import React from 'react'
import { useTranslation } from 'react-i18next'

export type CsvPreview = {
  headers: string[]
  rows: string[][]
}

export type CsvPreviewProps = {
  csvPreview: CsvPreview
  totalRows: number
}

const CSVPreview: React.FC<CsvPreviewProps> = ({ csvPreview, totalRows }) => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col overflow-x-scroll scrollbar">
      <div className="flex-1 w-full relative space-y-4 mt-20">
        <div className="flex justify-between">
          <h2 className="grow text-lg font-bold text-black-700 w-full">
            {t('end_users.preview')}
          </h2>
          <p className="w-full flex justify-end">
            {t('end_users.data_rows')}&nbsp;<strong>{totalRows}</strong>
          </p>
        </div>
        <table className=" border border-ui1 rounded-lg shadow-md">
          <thead className="bg-ui1 border rounded-lg">
            <tr>
              {csvPreview.headers.map((header, index) => (
                <th
                  key={index}
                  scope="col"
                  className="px-4 py-3.5 text-left text-xs font-semibold text-gray-3 uppercase break-normal"
                >
                  {t(header)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {csvPreview.rows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => {
                  return (
                    <td key={cellIndex} className="p-4 text-xs text-gray-1">
                      {cell}
                    </td>
                  )
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default CSVPreview
