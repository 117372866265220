import React from 'react'
import cx from 'classnames'
import { Icon, IconWeight, Plus } from '@phosphor-icons/react'
import './button.css'
import { useTranslation } from 'react-i18next'

type ButtonProps = React.ComponentProps<'button'> & {
  primary?: boolean
  size?: 'small-icon' | 'small' | 'normal' | 'big' | 'smallest'
  label: string
  iconWeight?: IconWeight
  IconLeft?: Icon
  IconRight?: Icon
  isIncentive?: boolean
  onClick?: () => void
  testId?: string
}

export const Button = ({ ...props }: ButtonProps) => {
  const { t } = useTranslation()
  const {
    className,
    primary = false,
    IconLeft,
    IconRight,
    label,
    size = 'normal',
    type = 'button',
    iconWeight = 'regular',
    isIncentive = false,
    testId,
    ...rest
  } = props
  const iconSize = size === 'small-icon' ? 16 : 24
  const mode = primary
    ? 'bg-primary text-white hover:bg-marine-300 focus:bg-primary focus-visible:outline focus:ring-primary'
    : 'bg-white text-black-1 border-1 border-black-1 hover:border-primary hover:text-primary focus:text-black-1 focus:border-black-1 focus:ring-black-1 '
  return (
    <button
      type={type}
      className={cx(
        'flex flex-row items-center justify-center',
        'rounded-md cursor-pointer border focus:ring-2 focus:ring-offset-2',
        `button--${size}`,
        mode,
        className,
      )}
      data-testid={testId}
      {...rest}
    >
      {isIncentive ? (
        <div className="space-y-2">
          <Plus size={35} color="#3CDC4B" className="m-auto" />
          <p className="text-sm font-bold">
            {t('add_incentive.add_incentive')}
          </p>
        </div>
      ) : (
        <div className="flex flex-row items-center gap-2 m-auto">
          {IconLeft && <IconLeft {...{ weight: iconWeight, size: iconSize }} />}
          <span className="whitespace-nowrap">{label}</span>
          {IconRight && (
            <IconRight {...{ weight: iconWeight, size: iconSize }} />
          )}
        </div>
      )}
    </button>
  )
}
