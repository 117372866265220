import { FormProps } from '@sourcelabbg/form/lib'
import { Controller } from 'react-hook-form'
import { DatePicker } from '@/components/ui/date-picker'
import { CustomDateInput } from './custom-types'

interface CustomDateProps {
  field: CustomDateInput
  formProps: FormProps
}

export default function CustomDate({ field, formProps }: CustomDateProps) {
  return (
    <Controller
      control={formProps.control}
      name={field.name}
      render={({ field: { onChange } }) => {
        return (
          <div data-testid={field.name}>
            <DatePicker
              value={formProps.formValues[field.name]}
              setValue={(d) => {
                onChange(d.substring(0, 10))
              }}
              minDate={field.minDate}
              maxDate={field.maxDate}
            />
          </div>
        )
      }}
    />
  )
}
