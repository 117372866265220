import { useSetRecoilState } from 'recoil'
import { activeStepState } from '@/store/atoms/active-step'
import { useEffect, useState } from 'react'
import INCENTIVE_CATEGORY_QUERY from '@/graphql/incentive/queries/categories'
import INCENTIVE_BY_CATEGORY_QUERY from '@/graphql/incentive/queries/incentive-incentive-category'
import ATTACH_CAMPAIGN_INCENTIVES from '@/graphql/campaign/mutations/attach-incentives'
import {
  Armchair,
  DotsThreeCircle,
  Hamburger,
  HandHeart,
  Icon,
} from '@phosphor-icons/react'

import { useMutation, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { IncentiveCategory, UpdateIncentiveItemInput } from '@/gql'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { CampaignIncentivesSchema } from '@/schema/campaign-incentives.schema'
import { CreateIncentive } from '../../../components/create-incentive'

import { GetCampaignFragment } from '../../../graphql/campaign/fragments/get-campaign'
import CampaignButtons from '../../../components/campaign-buttons'

import { Chip } from './chip'
import { Card } from './incentive-card'
import { createPortal } from 'react-dom'
import IncentiveModal from '@/components/create-incentive/incentive-modal'
import Loading from '@/components/loading'

export default function Incentives({ referral }: { referral: boolean }) {
  const { id } = useParams()
  const { t } = useTranslation()
  const setActiveStep = useSetRecoilState(activeStepState)
  const navigate = useNavigate()
  const { campaign } = useOutletContext<{ campaign: GetCampaignFragment }>()
  const [selectedCategory, setSelectedCategory] = useState<string | undefined>(
    undefined,
  )
  const [error, setError] = useState<string>()
  const [reuseSameIncentives, setReuseSameIncentives] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [editIncentive, setEditIncentive] = useState<UpdateIncentiveItemInput>()
  const { data: dataCategory } = useQuery(INCENTIVE_CATEGORY_QUERY, {})
  const { data: incentivesByCategory, loading: loadingIncentivesByCategory } =
    useQuery(INCENTIVE_BY_CATEGORY_QUERY, {
      variables: {
        incentiveCategoryId: selectedCategory ?? undefined,
        customerId: campaign?.customerId ?? undefined,
        campaignId: id,
      },
      fetchPolicy: 'network-only',
    })

  const [attachIncentives, { error: attachIncentivesError }] = useMutation(
    ATTACH_CAMPAIGN_INCENTIVES,
    {},
  )
  const parsedAttachIncentivesError =
    attachIncentivesError &&
    (JSON.parse(attachIncentivesError.message) as {
      message: string
      skuCodes: string
    })

  const [selectedIncentiveItems, setSelectedIncentiveItems] = useState<
    { id: string; skuCode?: string }[]
  >([])

  useEffect(() => setActiveStep(referral ? 6 : 5), [setActiveStep, referral])
  useEffect(() => {
    if (campaign?.campaignIncentives) {
      const filteredIncentives = campaign?.campaignIncentives
        ?.filter(
          (campaignIncentive) =>
            campaignIncentive?.isVisible &&
            (campaign?.reuseSameIncentives ||
              campaignIncentive?.referral === referral),
        )
        .map((campaignIncentive) => ({
          id: campaignIncentive?.incentiveItem.id || '',
          skuCode: campaignIncentive?.incentiveItem?.skuCode || '',
        }))

      setSelectedIncentiveItems(filteredIncentives)
      setReuseSameIncentives(campaign.reuseSameIncentives ?? false)
    }
  }, [campaign?.campaignIncentives, referral, campaign.reuseSameIncentives])

  // Update state of setSelectedIncentiveItems for lottery campaigns
  useEffect(() => {
    if (
      campaign.campaignCategory === 'lottery' &&
      !referral &&
      incentivesByCategory?.incentivesByCategory?.length > 0 &&
      selectedIncentiveItems.length === 0
    ) {
      const initialIncentives = incentivesByCategory.incentivesByCategory.map(
        (inc: UpdateIncentiveItemInput) => ({
          id: inc.id,
          skuCode: inc.skuCode ?? undefined,
        }),
      )
      setSelectedIncentiveItems(initialIncentives)
      setReuseSameIncentives(campaign.reuseSameIncentives ?? false)
    }
  }, [
    campaign.campaignCategory,
    referral,
    incentivesByCategory?.incentivesByCategory,
  ])

  if (!campaign || loadingIncentivesByCategory) {
    return <Loading />
  }

  const isLotteryCampaign =
    (campaign.campaignCategory === 'lottery' && !referral) ||
    (campaign.campaignCategory === 'lottery' &&
      !referral &&
      campaign.referralCampaign &&
      !reuseSameIncentives)

  const categoryIcons: Record<string, Icon> = {
    personal_care: HandHeart,
    home: Armchair,
    foods_and_drinks: Hamburger,
    other: DotsThreeCircle,
  }

  const onSubmit = () => {
    const incentives = {
      campaignId: id,
      incentiveItems: selectedIncentiveItems,
      referral,
      reuseSameIncentives,
    }
    const validatedCampaign = CampaignIncentivesSchema.safeParse(incentives)

    if (!validatedCampaign.success) {
      setError(t('validation_messages.empty_incentive'))
    } else {
      setError('')
      attachIncentives({
        variables: {
          incentives,
        },
        onCompleted() {
          !referral && campaign.referralCampaign && !reuseSameIncentives
            ? navigate(`/campaigns/referral-incentives/${id}`, {
                replace: true,
              })
            : navigate(`/campaigns/compose-creative/${id}`, {
                replace: false,
              })
        },
      })
    }
  }

  //TODO find better solution
  const reorderedIncentiveCategories =
    (dataCategory && [
      ...dataCategory.incentiveCategories,
      dataCategory.incentiveCategories[2],
    ]) ||
    []
  if (loadingIncentivesByCategory) return <div>{t('loading')}</div>

  return (
    <div className="w-full">
      <div className="flex flex-row items-center pb-4 gap-2">
        <p className="text-2xl font-bold">{t('incentives.title')}</p>
        {referral && (
          <p className="text-black-4 bg-violet-800 rounded px-1">
            {t('dashboard_labels.referral_campaign')}
          </p>
        )}
      </div>
      <div className="flex flex-row gap-4">
        {reorderedIncentiveCategories.map(
          (cat: IncentiveCategory, index: number) => {
            if (index === 2) return
            return (
              <Chip
                title={t(`incentive_categories.${cat.name}`)}
                IconLeft={categoryIcons[cat.name]}
                id={cat.id}
                key={cat.id}
                onClick={() => {
                  if (selectedCategory === cat.id) {
                    setSelectedCategory(undefined)
                  } else {
                    setSelectedCategory(cat.id)
                  }
                }}
                selectedCategory={selectedCategory}
              />
            )
          },
        )}
      </div>
      <div className="flex flex-wrap gap-6 py-8 w-full ">
        <CreateIncentive className="h-96 w-64 bg-gray-7 text-gray-1 border-dashed border-gray-6" />

        {incentivesByCategory?.incentivesByCategory?.length === 0 ? (
          <div>No items available</div>
        ) : (
          <>
            {incentivesByCategory.incentivesByCategory.map(
              (inc: UpdateIncentiveItemInput) => (
                <Card
                  incentive={{ ...inc }}
                  key={inc.id}
                  onClick={() => {
                    setEditIncentive({ ...inc })
                    setShowEditModal(true)
                  }}
                  selectedIncentiveItems={selectedIncentiveItems}
                  onClickSelected={() => {
                    if (selectedIncentiveItems.some((s) => s.id === inc.id)) {
                      setSelectedIncentiveItems(
                        selectedIncentiveItems.filter(
                          (item) => item.id !== inc.id,
                        ),
                      )
                    } else {
                      setSelectedIncentiveItems([
                        ...selectedIncentiveItems,
                        { id: inc.id, skuCode: inc.skuCode ?? undefined },
                      ])
                    }
                  }}
                  isLotteryCampaign={isLotteryCampaign}
                />
              ),
            )}
          </>
        )}
      </div>
      <div className="flex justify-end text-primary">
        {error && <div>{error}</div>}
        {attachIncentivesError && (
          <>
            <div>
              {t(`${parsedAttachIncentivesError?.message}`, {
                sku: `${parsedAttachIncentivesError?.skuCodes}`,
              })}
            </div>
          </>
        )}
      </div>
      {campaign.referralCampaign && !referral && (
        <div className="w-full flex justify-end">
          <label className="">
            <input
              type="checkbox"
              className="mx-2"
              checked={reuseSameIncentives}
              onChange={() => setReuseSameIncentives(!reuseSameIncentives)}
            />
            {t('repeat_incentive')}
          </label>
        </div>
      )}
      <CampaignButtons
        onSaveDraft={() => onSubmit()}
        onSubmit={() => onSubmit()}
        disableSaveDraft={
          referral ? true : campaign.referralCampaign && reuseSameIncentives
        }
      />
      {showEditModal &&
        createPortal(
          <IncentiveModal
            onCloseModal={() => setShowEditModal(false)}
            incentiveByModal={editIncentive}
          />,
          document.body,
        )}
    </div>
  )
}
