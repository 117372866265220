import { useTranslation } from 'react-i18next'
import { WarningDiamond } from '@phosphor-icons/react'
import { Button } from '@/components/button'
import { EndUsersUploadedError } from '@/gql'

type ErrorModalProps = {
  array: EndUsersUploadedError[]
  onClick: () => void
}

export default function ErrorModal({ array, onClick }: ErrorModalProps) {
  const { t } = useTranslation()

  return (
    <div className="absolute h-screen">
      <div className="fixed top-0 left-0 z-30 w-full h-screen bg-white opacity-75"></div>
      <div className="flex justify-center fixed top-10 left-2/4 z-40 bg-blue-300">
        <div className="flex flex-col w-[500px] absolute items-start p-8 z-50 bg-white rounded-md shadow-md overflow-y-scroll">
          <div className="flex flex-col justify-center">
            <div className="flex flex-row justify-center">
              <div className="my-auto">
                <WarningDiamond size={42} color="#490091" weight="duotone" />
              </div>
              <span className="pl-2.5">
                <p className="text-lg font-bold text-gray-9 pb-2">
                  {t('end_users.missing_data')}
                </p>
                <p className="text-sm font-normal text-gray-10">
                  {t('end_users.missing_data_text')}
                </p>
              </span>
            </div>
            <div className="h-full">
              <div className="overflow-x mt-6 overflow-auto h-96">
                <table className="table-auto w-full">
                  <tbody className="">
                    <tr className="text-xs">
                      <th className="text-start p-2">
                        {t('end_users.upload')}
                      </th>
                      <th className="text-start p-2">
                        {t('end_users.missing')}
                      </th>
                    </tr>
                    {array?.map(
                      (record: EndUsersUploadedError, index: number) => (
                        <tr className="text-sm" key={index}>
                          <td className="p-4 border border-gray-7">
                            {record.user?.trim()}
                          </td>
                          <td className="p-4 border border-gray-7 text-secondary">
                            {record.missingFields
                              ? record.missingFields
                                  .map((field) =>
                                    field ? t(`end_users.errors.${field}`) : '',
                                  )
                                  .join(', ')
                              : ''}
                          </td>
                        </tr>
                      ),
                    )}
                  </tbody>
                </table>
              </div>
              <div className="flex bg-white justify-end py-4">
                <Button
                  primary={true}
                  label={t('end_users.ok')}
                  onClick={onClick}
                  className="px-6"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
