import { useEffect } from 'react'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { EmailForm } from './email-form'
import { useTranslation } from 'react-i18next'
import { EmailFormData } from '@/pages/campaign/emails'

interface EmailNotificationDialogProps {
  trigger?: React.ReactNode
  header: string
  triggerType: string
  onDelete?: () => void
  onSubmit: (data: EmailFormData) => void
  formData?: EmailFormData
  existingTriggerTypes: string[]
  index: number
  campaignId: string
  campaignStartDate: string
  campaignEndDate: string
  title?: string
  open?: boolean
  onOpenChange?: (open: boolean) => void
}

export function EmailNotificationDialog({
  trigger,
  header,
  triggerType,
  onDelete,
  onSubmit,
  formData = {},
  existingTriggerTypes,
  index,
  campaignId,
  campaignStartDate,
  campaignEndDate,
  title,
  open,
  onOpenChange,
}: EmailNotificationDialogProps) {
  const { t } = useTranslation()

  // Add cleanup function when dialog closes to prevent pointer events from being disabled
  const handleOpenChange = (newOpen: boolean) => {
    if (!newOpen) {
      // Close any open select dropdowns by simulating an Escape key press
      const escapeEvent = new KeyboardEvent('keydown', {
        key: 'Escape',
        bubbles: true,
      })
      document.dispatchEvent(escapeEvent)

      // Force document cleanup
      document.body.style.pointerEvents = 'auto'
      document.body.classList.remove('overflow-hidden')
    }

    if (onOpenChange) {
      onOpenChange(newOpen)
    }
  }

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (document.body) {
        document.body.style.pointerEvents = 'auto'
      }
    }
  }, [])

  return (
    <Dialog open={open} onOpenChange={handleOpenChange}>
      {trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}

      <DialogContent
        className="sm:max-w-xl max-h-[95%] overflow-y-auto overflow-y-auto fixed left-[50%] top-[50%] z-50 grid w-full translate-x-[-50%] translate-y-[-50%] gap-4 border bg-background p-6 shadow-lg"
        onInteractOutside={(e) => {
          e.preventDefault()
          handleOpenChange(false)
        }}
      >
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>
            {t('emails.emails_description')}
          </DialogDescription>
        </DialogHeader>

        <EmailForm
          onDelete={onDelete}
          header={header}
          onClose={() => onOpenChange && onOpenChange(false)}
          onSubmit={onSubmit}
          triggerType={triggerType}
          values={formData}
          existingTriggerTypes={existingTriggerTypes}
          currentIndex={index}
          campaignId={campaignId}
          campaignStartDate={campaignStartDate}
          campaignEndDate={campaignEndDate}
        />
      </DialogContent>
    </Dialog>
  )
}
