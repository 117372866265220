import { Copy, Export } from '@phosphor-icons/react'
import { PartialEndUser, EndUserCampaign } from '../../../../server/src/graphql'
import { useState } from 'react'
import Header from '../../components/header'
import { BackButton, NextButton } from '../../components/buttons'
import cx from 'classnames'
import i18n from '../../../../client/src/i18n'
import { endUserType } from '../../utils/user-type'
import { TFunction } from 'i18next'
import { NavigateFunction } from 'react-router-dom'

export default function Referral({
  campaign,
  theme,
  endUser,
  id,
  t,
  pathname,
  navigate,
  integrations
}: {
  integrations: { features: Record<string, unknown> }[]
  campaign: EndUserCampaign
  theme: string
  endUser: PartialEndUser
  t: TFunction<'translation', undefined>
  pathname: string
  id?: string
  navigate: NavigateFunction
}) {
  const [showCopyToClipboardTooltip, setShowCopyToClipboardTooltip] =
    useState<boolean>(false)

  if (endUser?.referralOf && !campaign?.referralCampaign) {
    return <p>404</p>
  }

  const referralLink =
    import.meta.env.VITE_PUBLIC_URL + '/' + endUser.id + '/referral'

  const isHeardFoundation = integrations?.some(
    (integration) =>
      integration.features?.['template'] === 'heart-foundation',
  )

  const shareOptions = {
    title: isHeardFoundation ? t('ec.invite.share_msg_heart_foundation') : t('ec.invite.share_msg'),
    text: isHeardFoundation ? t('ec.invite.share_msg_heart_foundation') : t('ec.invite.share_msg'),
    url: referralLink,
  }
  const language = i18n.language
  const typeOfUser = endUserType(endUser.referral)
  const translations =
    campaign?.ecFlowTexts?.ecFlowTexts?.[typeOfUser]?.['invite']?.[language]
  const handleCopyToClipboard = () => {
    window.navigator.clipboard.writeText(referralLink)
    setShowCopyToClipboardTooltip(true)
    setTimeout(() => {
      setShowCopyToClipboardTooltip(false)
    }, 2000)
  }
  return (
    <div
      className={cx(
        `theme-${theme}`,
        'flex flex-col justify-between md:justify-center theme-theme2:bg-white',
      )}
    >
      <Header
        title={
          translations?.header ? translations?.header : t('ec.invite.title')
        }
        subTitle={
          translations?.text ? translations?.text : t('ec.invite.sub_title')
        }
        theme={theme}
        pathname={pathname}
      />

      <div className="m-auto bg-white h-full grid gap-y-2 py-4">
        <div className="flex relative justify-between bg-ui1 rounded-lg p-4 text-gray-1 text-xl font-bold m-auto mx-4">
          <span className="self-center text-xs">{referralLink}</span>
        </div>
        <div className="flex flex-row justify-center pt-3.5 gap-3 text-gray-1 text-xs">
          <div className="flex flex-col items-center">
            <div className="p-4 rounded-full items-center bg-ui1 cursor-pointer">
              <Copy
                size={28}
                weight="fill"
                onClick={handleCopyToClipboard}
                color="gray-1"
              />
              {showCopyToClipboardTooltip && (
                <span className="bg-gray-800 text-white text-center whitespace-nowrap absolute rounded-lg p-2 transform -translate-y-12">
                  {/* TODO add translation or filed in flexible copy */}
                  Gekopieerd naar het klembord
                </span>
              )}
            </div>
            <p className="pt-1">{t('ec.invite.copy')}</p>
          </div>
          {window.navigator?.canShare?.(shareOptions) && (
            <div className="flex flex-col items-center">
              <div className="p-4 rounded-full bg-green-50 cursor-pointer">
                <Export
                  size={28}
                  onClick={() => window.navigator.share(shareOptions)}
                  color="gray-1"
                />
              </div>
              <p className="pt-1">{t('ec.invite.share')}</p>
            </div>
          )}
        </div>
        <div className="flex flex-col gap-4 mb-8 bg-white">
          {campaign.happinessCheck ? (
            <NextButton
              onClick={() => navigate(`/${id}/happiness-check`)}
              t={t}
            />
          ) : (
            <NextButton
              label={t('ec.finish')}
              onClick={() => navigate(`/${id}/success`)}
              t={t}
            />
          )}
          <BackButton navigate={navigate} t={t} />
        </div>
      </div>
    </div>
  )
}
