import { EndUser, EndUsersUploadedError } from '@/gql'

import { Upload } from '@phosphor-icons/react'
import { parse } from 'csv-parse/browser/esm/sync'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import readXlsxFile from 'read-excel-file'
import colors from '../../../../tailwind.config'
import CampaignButtons from '../../../components/campaign-buttons'
import { CsvFieldsMapping } from '../../../components/csv/csv-field-mapping'
import CSVFieldSelector from '../../../components/csv/csv-field-selector'
import CSVPreview from '../../../components/csv/csv-preview'
import FileUpload from '../../../components/file-upload'

import END_USERS from '../../../graphql/campaign/queries/end-users'

import { activeStepState } from '../../../store/atoms/active-step'
import { tokenState } from '../../../store/atoms/token'
import { createPortal } from 'react-dom'
import ErrorModal from '@/components/upload-end-users-error-modal'
import { GetCampaignFragment } from '@/graphql/campaign/fragments/get-campaign'
import { Separator } from '@/components/ui/separator'
import OneCommunityPurchases from './integrations/one-community-purchases'
import { useQuery } from '@apollo/client'
import OneCommunityForm from './integrations/one-community-form'

const tableHeaders = [
  'end_users.table_header.first_name',
  'end_users.table_header.middle',
  'end_users.table_header.last_name',
  'end_users.table_header.email',
  'end_users.table_header.phone_number',
  'end_users.table_header.postal_code',
  'end_users.table_header.city',
  'end_users.table_header.street_name',
  'end_users.table_header.house_number',
  'end_users.table_header.country',
  'end_users.table_header.birthday',
  'end_users.table_header.sku',
  'end_users.table_header.digital_code',
  'end_users.table_header.postnl_code',
  'end_users.table_header.reference_code',
]
type ParsedCsv = {
  [key: string]: string
}[]
const isParsedCsv = (data: ParsedCsv): data is ParsedCsv => {
  return (data as ParsedCsv)[0]['Voornaam'] ? true : false
}

export default function EndUsers() {
  const { campaign } = useOutletContext<{ campaign: GetCampaignFragment }>()

  const setActiveStep = useSetRecoilState(activeStepState)
  const token = useRecoilValue(tokenState)
  const { t } = useTranslation()
  const currentCampaignId = useParams().id
  useEffect(() => setActiveStep(2), [setActiveStep])
  const navigate = useNavigate()
  const [file, setFile] = useState<File | undefined>(undefined)
  const [parsedCsv, setParsedCsv] = useState<ParsedCsv>()
  const [hasCsvError, setHasCsvError] = useState<boolean>(false)
  const [showModal, setShowModal] = useState(false)
  const isOneCommunityIntegration = useMemo(
    () =>
      campaign?.customer?.integrations?.find(
        (integration) => integration?.type === 'ONE_COMMUNITY',
      ),
    [campaign?.customer],
  )

  const [errors, setErrors] = useState<EndUsersUploadedError[]>()
  const [missingSKUs, setMissingSKUs] = useState<string[]>([])

  const END_USER_FIELDS_MAPPING: CsvFieldsMapping = {
    firstName: {
      displayName: 'end_users.table_header.first_name',
      mappedName: 'Voornaam',
    },
    middle: {
      displayName: 'end_users.table_header.middle',
      mappedName: 'Tussenvoegsel',
    },
    lastName: {
      displayName: 'end_users.table_header.last_name',
      mappedName: 'Achternaam',
    },
    email: {
      displayName: 'end_users.table_header.email',
      mappedName: 'E-mail',
    },
    phoneNumber: {
      displayName: 'end_users.table_header.phone_number',
      mappedName: 'Telefoonnummer',
    },
    postalCode: {
      displayName: 'end_users.table_header.postal_code',
      mappedName: 'Postcode',
    },
    city: {
      displayName: 'end_users.table_header.city',
      mappedName: 'Plaats',
    },
    streetName: {
      displayName: 'end_users.table_header.street_name',
      mappedName: 'Straatnaam',
    },
    houseNumber: {
      displayName: 'end_users.table_header.house_number',
      mappedName: 'Huisnummer',
    },
    country: {
      displayName: 'end_users.table_header.country',
      mappedName: 'Land',
    },
    birthday: {
      displayName: 'end_users.table_header.birthday',
      mappedName: 'Geboortedag',
    },
    sku: {
      displayName: 'end_users.table_header.sku',
      mappedName: 'SKU',
    },
    digitalCode: {
      displayName: 'end_users.table_header.digital_code',
      mappedName: 'Code',
    },
    postnlCode: {
      displayName: 'end_users.table_header.postnl_code',
      mappedName: 'Dutch Post Code',
    },
    referenceCode: {
      displayName: 'end_users.table_header.reference_code',
      mappedName: 'Reference Code',
    },
  }

  const [endUserFieldsMapping, setEndUserFieldsMapping] =
    useState<CsvFieldsMapping>(END_USER_FIELDS_MAPPING)

  const [mappedFields, setMappedFields] = useState<{
    [field: string]: boolean | undefined
  }>({})

  const { data: databaseUsers, refetch } = useQuery(END_USERS, {
    variables: { campaignId: currentCampaignId },
  })

  const savedUsers = useMemo(() => {
    if (databaseUsers?.endUsers?.length) {
      return databaseUsers?.endUsers?.map((endUser: EndUser) => [
        endUser.firstName,
        endUser.middle,
        endUser.lastName,
        endUser.email,
        endUser.phoneNumber,
        endUser.address?.postcode,
        endUser.address?.city,
        endUser.address?.streetName,
        endUser.address?.houseNumber,
        endUser.address?.country,
        endUser.birthday,
        endUser.sku,
        endUser.digitalCode,
        endUser.postnlCode,
        endUser.referenceCode,
      ])
    }

    return []
  }, [databaseUsers])

  const handleFileUpload = useCallback((file?: File) => {
    if (!file) {
      setParsedCsv(undefined)
      setFile(undefined)
      return
    }
    setFile(file)
    if (file.name.endsWith('.xlsx')) {
      readXlsxFile(file).then((rows) => {
        rows.shift()
        const header = rows.shift()
        if (!header) return
        const data = rows.map((row) => {
          return header.reduce(
            (all: Record<string, unknown>, current: unknown, i: number) => {
              all[current?.toString() || i] = row[i]
              return all
            },
            {},
          )
        })
        setParsedCsv(data as ParsedCsv)
        isParsedCsv(data as ParsedCsv)
          ? setHasCsvError(false)
          : setHasCsvError(true)
      })
    } else {
      const fr = new FileReader()
      fr.onload = () => {
        if (!fr.result) return
        const fileAsString = fr.result as string
        try {
          const parsedCsv = parse(fileAsString, {
            columns: true,
            skip_empty_lines: true,
          })
          setParsedCsv(parsedCsv)
          setHasCsvError(false)
        } catch (error) {
          error && setHasCsvError(true)
        }
      }

      fr.readAsText(file)
    }
  }, [])

  const handleFieldsMappingChanged = useCallback(
    (newFieldMapping: CsvFieldsMapping) => {
      setEndUserFieldsMapping(newFieldMapping)
    },
    [],
  )

  useEffect(() => {
    if (!parsedCsv) {
      setMappedFields({})
      return
    }

    const newMappedFields = Object.entries(endUserFieldsMapping).reduce(
      (mappedFields, [field, { mappedName }]) => {
        return {
          ...mappedFields,
          [field]: parsedCsv.some((row) => row[mappedName] !== undefined),
        }
      },
      {},
    )
    setMappedFields(newMappedFields)
  }, [endUserFieldsMapping, parsedCsv])

  const csvPreviewTableData = useMemo(() => {
    const headers = Object.entries(endUserFieldsMapping).map(
      ([, { displayName }]) => displayName,
    )
    const rows =
      parsedCsv?.slice(0, 15).map((row) => {
        return Object.entries(endUserFieldsMapping).map(
          ([, { mappedName }]) => row[mappedName],
        )
      }) || []
    return {
      headers,
      rows,
    }
  }, [parsedCsv, endUserFieldsMapping])

  const uploadEndUsersCsv = useCallback(async () => {
    if (!file || !currentCampaignId || !endUserFieldsMapping) return
    const formData = new FormData()
    formData.append('file', file as Blob)
    formData.append('campaignId', currentCampaignId?.toString())
    Object.entries(endUserFieldsMapping).forEach(([field, fieldDefinition]) => {
      formData.append(`mappedFields[${field}]`, fieldDefinition.mappedName)
    })
    try {
      const response = await fetch('/upload/end-users', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${token?.__raw}`,
        },
      })
      const data = await response.json()

      // Parse message once if it's a JSON string
      const parsedMessage =
        typeof data?.message === 'string'
          ? JSON.parse(data.message)
          : data.message

      // Handle validation errors missing SKU codes in the data upload
      if (
        Array.isArray(parsedMessage) &&
        parsedMessage[0]?.missingFields?.length > 0
      ) {
        setErrors(parsedMessage)
        setShowModal(true)
        return false
      }

      // Handle missing SKU codes in the SKU builder
      if (
        parsedMessage?.code === 'missing_sku_codes' &&
        parsedMessage.skuCodes?.length > 0
      ) {
        setMissingSKUs(parsedMessage.skuCodes)
        return false
      }

      return true
    } catch (error) {
      console.error('Error uploading users:', error)
      return false
    }
  }, [file, currentCampaignId, token, endUserFieldsMapping])

  const onSave = useCallback(
    async (draft: boolean) => {
      const uploaded = await uploadEndUsersCsv()
      if (uploaded === false) return

      if (!draft && databaseUsers) {
        refetch()
        navigate(`/campaigns/questionnaire/${currentCampaignId}`)
      } else {
        await uploadEndUsersCsv()
      }
    },
    [uploadEndUsersCsv, navigate, currentCampaignId, databaseUsers, refetch],
  )

  return (
    <div className="flex-1 w-96">
      <div className="flex flex-row">
        <h2 className="grow text-lg font-bold text-black-700">
          {t('end_users.data_upload')}
        </h2>
      </div>

      <div className="flex justify-between pt-4">
        <div>File</div>
        <div className="flex flex-row cursor-pointer">
          <Upload
            className="self-center mr-2"
            color={colors.theme.extend.colors.primary}
          />
          <a
            className="text-primary"
            href="/download/example.csv"
            download={true}
          >
            {t('end_users.download_example')}
          </a>
        </div>
      </div>

      <span className="relative">
        {showModal &&
          createPortal(
            <ErrorModal
              array={errors ?? []}
              onClick={() => setShowModal(false)}
            />,
            document.body,
          )}
      </span>

      <FileUpload
        allowedFileTypes={['csv', 'xlsx']}
        onFileUpload={handleFileUpload}
      />
      {hasCsvError && file && (
        <p className="text-primary">{t('upload_csv_error')}</p>
      )}
      {isOneCommunityIntegration && (
        <>
          <div className="my-6 flex gap-4 align-middle items-center justify-center">
            Or <Separator />
          </div>
          <OneCommunityPurchases
            campaign={campaign}
            onComplete={() => refetch()}
            setErrors={setErrors}
            setShowModal={setShowModal}
          />
          <OneCommunityForm
            campaign={campaign}
            onComplete={() => refetch()}
            setErrors={setErrors}
            setShowModal={setShowModal}
          />
        </>
      )}

      {(parsedCsv && csvPreviewTableData && (
        <div className="flex">
          <div className="flex-1 w-96">
            <h2 className="grow my-4 text-lg font-bold text-black-700">
              {t('end_users.data_check')}
            </h2>
            <CSVFieldSelector
              fieldsMapping={endUserFieldsMapping}
              mappedFields={mappedFields}
              onFieldsMappingChanged={handleFieldsMappingChanged}
            />

            <CSVPreview
              csvPreview={csvPreviewTableData}
              totalRows={parsedCsv.length}
            />
          </div>
        </div>
      )) ||
        (savedUsers.length > 0 && (
          <div className="flex">
            <div className="flex-1 w-96">
              <CSVPreview
                csvPreview={{
                  headers: tableHeaders,
                  rows: savedUsers,
                }}
                totalRows={savedUsers.length}
              />
            </div>
          </div>
        ))}

      {missingSKUs.length > 0 && (
        <div className="flex flex-col w-full justify-end items-end mt-10">
          <p className="text-primary my-2">
            {t('end_users.missing_sku_codes')}:
          </p>
          <ul className="list-disc list-inside">
            {missingSKUs.map((sku, index) => (
              <li key={index} className="text-primary">
                {sku}
              </li>
            ))}
          </ul>
        </div>
      )}

      <CampaignButtons
        onSaveDraft={() => onSave(true)}
        onSubmit={() => onSave(false)}
      />
    </div>
  )
}
