import { AlphaTypes } from '../form/custom-types'

export default [
  {
    name: 'gender',
    type: 'custom',
    customControlType: 'radioGroupGender',
    uiOptions: {
      label: 'geslacht',
      labelClassName: 'pl-4 text-md text-theme-primary',
    },
  },
  {
    name: 'firstName',
    type: 'input',
    uiOptions: {
      label: 'Voornaam',
      labelClassName: 'pl-4 text-md text-theme-primary',
      inputType: 'text',
    },
  },
  {
    name: 'infix',
    type: 'input',
    uiOptions: {
      label: 'Tussenvoegsel',
      labelClassName: 'pl-4 text-md text-theme-primary',
      inputType: 'text',
    },
  },
  {
    name: 'surname',
    type: 'input',
    uiOptions: {
      label: 'Achternaam',
      labelClassName: 'pl-4 text-md text-theme-primary',
      inputType: 'text',
      className: 'w-full ',
    },
  },
  {
    name: 'postcode',
    type: 'input',
    uiOptions: {
      label: 'Postcode',
      labelClassName: 'pl-4 text-md text-theme-primary',
      inputType: 'text',
    },
  },
  {
    name: 'houseNumber',
    type: 'input',
    uiOptions: {
      label: 'huisnummer',
      labelClassName: 'pl-4 text-md text-theme-primary',
      inputType: 'text',
    },
  },
  {
    name: 'addition',
    type: 'input',
    uiOptions: {
      label: 'Toevoeging',
      labelClassName: 'pl-4 text-md text-theme-primary',
      inputType: 'text',
    },
  },
  {
    name: 'email',
    type: 'input',
    uiOptions: {
      label: 'E-mail',
      labelClassName: 'pl-4 text-md text-theme-primary',
      inputType: 'text',
    },
  },
  {
    name: 'phone',
    type: 'input',
    uiOptions: {
      label: 'Telefoonnummer',
      labelClassName: 'pl-4 text-md text-theme-primary',
      inputType: 'text',
    },
  },
  {
    name: 'birthday',
    type: 'custom',
    customControlType: 'dateInput',
    uiOptions: {
      label: 'Geboortedag',
      labelClassName: 'pl-4 text-md text-theme-primary',
    },
  },
] as AlphaTypes[]
