import { FormProps } from '@sourcelabbg/form/lib'
import { Controller } from 'react-hook-form'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { QuillEditorInput } from './custom-types'
import { useTranslation } from 'react-i18next'

export type QuillEditorProps = {
  field: QuillEditorInput
  formProps: FormProps
  maxChars?: number
}

const QuillEditor = ({ field, formProps }: QuillEditorProps) => {
  const {t } = useTranslation()
  const modules = {
    toolbar: false,
  }

  const stripHtmlTags = (html: string) => {
    const div = document.createElement('div')
    div.innerHTML = html
    return div.textContent || div.innerText || ''
  }

  return (
    <>
      <Controller
        control={formProps.control}
        name={field.name}
        render={({ field: { value, onChange } }) => {
          const plainTextContent = stripHtmlTags(
            formProps.formValues[field.name],
          )
          return (
            <>
              <ReactQuill
                value={value}
                onChange={(content) => {
                  const emptyContentRegex = /^<p><br><\/p>$/
                  const sanitizedContent = emptyContentRegex.test(content)
                    ? ''
                    : content

                  const plainTextContent = stripHtmlTags(sanitizedContent)

                  if (
                    field.maxChars &&
                    plainTextContent.length <= field.maxChars
                  ) {
                    onChange(sanitizedContent)
                  } else if (
                    field.maxChars &&
                    plainTextContent.length > field.maxChars
                  ) {
                    let currentPlainTextLength = 0
                    const parser = new DOMParser()
                    const doc = parser.parseFromString(
                      sanitizedContent,
                      'text/html',
                    )
                    const walker = document.createTreeWalker(
                      doc.body,
                      NodeFilter.SHOW_TEXT,
                    )

                    while (walker.nextNode()) {
                      const node = walker.currentNode
                      if (node.textContent) {
                        const remainingChars =
                          field.maxChars - currentPlainTextLength
                        if (node.textContent.length <= remainingChars) {
                          currentPlainTextLength += node.textContent.length
                        } else {
                          node.textContent = node.textContent.substring(
                            0,
                            remainingChars,
                          )

                          break
                        }
                      }
                    }

                    const serializer = new XMLSerializer()
                    const truncatedHtmlContent = serializer.serializeToString(
                      doc.body,
                    )

                    onChange(truncatedHtmlContent)
                  }
                }}
                modules={modules}
                theme="snow"
                className={field.uiOptions?.className}
              />

              {field.maxChars && (
                <p className="text-xs text-right mt-2">
                  {field.maxChars - plainTextContent.length}{' '}
                  {field.maxChars -
                    stripHtmlTags(formProps.formValues[field.name]).length ===
                  1
                    ? t('character')
                    : t('characters')}
                </p>
              )}
            </>
          )
        }}
      />
    </>
  )
}

export default QuillEditor
