import { useParams, useNavigate, useOutletContext } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { activeStepState } from '../../store/atoms/active-step'
import { useEffect, useMemo } from 'react'
import CREATE_EC_FLOW_TEXTS from '../../graphql/campaign/mutations/create-ec-flow-texts'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { GetCampaignFragment } from '../../graphql/campaign/fragments/get-campaign'
import CampaignButtons from '../../components/campaign-buttons'
import {
  CampaignReminders,
  ECFlowTextsFormType,
} from '@/schema/ec-flow-texts.schema'
import { EmailNotificationDialog } from '@/components/emails/email-notification-dialog'
import { useState } from 'react'
import { Button } from '@/components/ui/button'
import { ChevronRight } from 'lucide-react'

export type EmailFormData = {
  header?: string
  text?: string
  trigger?: string
  imageUrl?: string
  date?: string
  happinessRating?: string[]
}

interface EmailNotification {
  header: string
  emailType: string
  triggerType: string
  formData?: EmailFormData
}
export default function Emails() {
  const { id } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { campaign: campaignData } = useOutletContext<{
    campaign: GetCampaignFragment
  }>()

  const setActiveStep = useSetRecoilState(activeStepState)
  const [createECFlowTexts] = useMutation(CREATE_EC_FLOW_TEXTS, {})

  const campaign: ECFlowTextsFormType = useMemo(() => {
    return campaignData?.ecFlowTexts || {}
  }, [campaignData])

  const values = useMemo(
    () => campaign?.ecFlowTexts?.email?.nl,
    [campaign.ecFlowTexts],
  )

  useEffect(() => setActiveStep(8), [setActiveStep])

  const defaultEmailNotification: EmailNotification = {
    header: 'Proces succesvol afgerond',
    emailType: 'Bevestigingsemail',
    triggerType: 'successfullySelectedIncentive',
    formData: {
      header: values?.header ?? '',
      text: values?.text ?? '',
      trigger: 'successfullySelectedIncentive',
    },
  }

  const getTriggerDetails = (
    triggerType: string,
  ): { header: string; emailType: string } => {
    switch (triggerType) {
      case 'successfullySelectedIncentive':
        return {
          header: 'Proces succesvol afgerond',
          emailType: 'Bevestigingsemail',
        }
      case 'noActionTaken':
        return {
          header: 'Geen actie ondernomen',
          emailType: 'Herinneringsmail',
        }
      case 'incompleteOnlineProcess':
        return {
          header: 'Proces niet volledig afgerond',
          emailType: 'Herinneringsmail',
        }
      case 'happinessCheck':
        return {
          header: 'Happiness check',
          emailType: 'Reactie op gegeven beoordeling',
        }
      default:
        return { header: 'Unknown trigger', emailType: 'System email' }
    }
  }

  // Function to convert reminders object to an array of notifications
  const convertRemindersToNotifications = (
    campaignReminders?: CampaignReminders,
  ): EmailNotification[] => {
    if (!campaignReminders) return []

    const notifications: EmailNotification[] = []

    // Convert each reminder type to a notification
    Object.entries(campaignReminders).forEach(([key, value]) => {
      if (key === 'enabled') return // Skip the enabled flag

      const { header, emailType } = getTriggerDetails(key)

      if (typeof value !== 'object') return

      notifications.push({
        header,
        emailType,
        triggerType: key,
        formData: {
          header: value.header || '',
          text: value.text || '',
          trigger: key,
          imageUrl: value.imageUrl || '',
          ...(key === 'noActionTaken' && 'date' in value && value.date
            ? { date: value.date }
            : {}),
          ...(key === 'happinessCheck' &&
          'happinessRating' in value &&
          Array.isArray(value.happinessRating)
            ? { happinessRating: value.happinessRating }
            : {}),
        },
      })
    })

    return notifications
  }

  const [emailNotifications, setEmailNotifications] = useState<
    EmailNotification[]
  >(() => {
    // Start with the success email notification
    const notifications = [defaultEmailNotification]

    // Add any existing reminders from the campaign data
    if (campaign?.reminders) {
      const reminderNotifications = convertRemindersToNotifications(
        campaign.reminders,
      )
      notifications.push(...reminderNotifications)
    }

    return notifications
  })

  const handleAddEmail = (formData: EmailFormData) => {
    const { header, emailType } = getTriggerDetails(formData.trigger || '')
    setEmailNotifications((prev) => [
      ...prev,
      {
        header,
        emailType,
        triggerType: formData.trigger || '',
        formData,
      },
    ])
  }

  const handleUpdateEmail = (index: number, formData: EmailFormData) => {
    setEmailNotifications((prev) =>
      prev.map((notification, i) => {
        if (i === index) {
          // Get new header and emailType based on the submitted trigger
          const { header, emailType } = getTriggerDetails(
            formData.trigger || notification.triggerType,
          )
          return {
            ...notification,
            header,
            emailType,
            triggerType: formData.trigger || notification.triggerType,
            formData,
          }
        }
        return notification
      }),
    )
  }

  const handleDeleteEmail = (index: number) => {
    setEmailNotifications((prev) => prev.filter((_, i) => i !== index))
  }

  const handleSubmitAll = (action: 'next' | 'save') => {
    // Find the success email notification
    const successEmail = emailNotifications.find(
      (notification) =>
        notification.triggerType === 'successfullySelectedIncentive',
    )

    // Get all other notifications for reminders
    const reminderNotifications = emailNotifications.filter(
      (notification) =>
        notification.triggerType !== 'successfullySelectedIncentive',
    )

    // Build reminders object
    const reminders = {
      enabled: reminderNotifications.length > 0,
      ...reminderNotifications.reduce((acc, notification) => {
        const reminderKey = notification.triggerType
        return {
          ...acc,
          [reminderKey]: {
            header: notification.formData?.header || '',
            text: notification.formData?.text || '',
            imageUrl: notification.formData?.imageUrl || '',
            ...(reminderKey === 'noActionTaken' && notification.formData?.date
              ? { date: notification.formData.date }
              : {}),
            ...(reminderKey === 'happinessCheck' &&
            notification.formData?.happinessRating
              ? { happinessRating: notification.formData.happinessRating }
              : {}),
          },
        }
      }, {}),
    }

    createECFlowTexts({
      variables: {
        ecFlowTexts: {
          id: campaign?.id ?? id,
          ecFlowTexts: {
            ...campaign.ecFlowTexts,
            // Save success email as before
            email: {
              nl: {
                header: successEmail?.formData?.header || '',
                text: successEmail?.formData?.text || '',
                imageUrl: successEmail?.formData?.imageUrl || '',
              },
            },
          },
          // Add reminders object
          reminders,
        },
      },
      onCompleted() {
        if (action === 'next') {
          navigate(`/campaigns/happiness-check/${campaignData.id}`)
        }
      },
    })
  }

  // State to control dialog
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [currentEmail, setCurrentEmail] = useState<{
    notification?: EmailNotification
    index: number
  }>({
    notification: undefined,
    index: -1,
  })

  // Function to open dialog for adding a new email
  const handleOpenAddDialog = () => {
    setCurrentEmail({
      notification: undefined,
      index: -1,
    })
    setIsDialogOpen(true)
  }

  // Function to open dialog for editing an existing email
  const handleOpenEditDialog = (
    notification: EmailNotification,
    index: number,
  ) => {
    setCurrentEmail({
      notification,
      index,
    })
    setIsDialogOpen(true)
  }

  // Function to handle form submission
  const handleDialogSubmit = (data: EmailFormData) => {
    if (currentEmail.index >= 0) {
      // Edit mode
      handleUpdateEmail(currentEmail.index, data)
    } else {
      // Add mode
      handleAddEmail(data)
    }
    setIsDialogOpen(false)
  }

  return (
    <div className="flex flex-col w-full">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold m-0">{t('emails.emails')}</h1>
          <p className="text-sm">{t('emails.emails_description')}</p>
        </div>

        {/* Add new email button */}
        <div>
          <Button
            className="p-2 px-3 h-6 bg-white text-primary border border-primary flex items-center"
            variant="outline"
            size="sm"
            onClick={handleOpenAddDialog}
          >
            {t('emails.add_email')}
          </Button>
        </div>
      </div>
      {/* Email notification list */}
      <div className="flex flex-col mt-8 space-y-2">
        {emailNotifications.map((notification, index) => (
          <div
            key={`${index}-${notification.triggerType}`}
            className="flex items-center justify-between p-2 border rounded-lg hover:bg-gray-50 cursor-pointer"
            onClick={() => handleOpenEditDialog(notification, index)}
          >
            <div className="space-y-1">
              <h3 className="font-extrabold text-sm">{notification?.header}</h3>
              <p className="text-sm text-gray-500">{notification?.emailType}</p>
            </div>
            <ChevronRight className="h-4 w-4 text-gray-400" />
          </div>
        ))}
      </div>

      {/* Single email notification dialog */}
      <EmailNotificationDialog
        header={currentEmail?.notification?.header || 'Add new email'}
        triggerType={currentEmail?.notification?.triggerType || ''}
        onDelete={
          currentEmail?.index >= 0 &&
          currentEmail.notification?.triggerType !==
            'successfullySelectedIncentive'
            ? () => {
                handleDeleteEmail(currentEmail.index)
                setIsDialogOpen(false)
              }
            : undefined
        }
        onSubmit={handleDialogSubmit}
        formData={currentEmail?.notification?.formData}
        existingTriggerTypes={emailNotifications
          .map((n, i) => (i !== currentEmail?.index ? n.triggerType : null))
          .filter((type): type is string => !!type)}
        index={currentEmail?.index || -1}
        campaignId={campaignData.id}
        campaignStartDate={campaignData.startDate}
        campaignEndDate={campaignData.endDate}
        title={
          currentEmail?.notification
            ? t('emails.edit_email')
            : t('emails.add_email')
        }
        open={isDialogOpen}
        onOpenChange={setIsDialogOpen}
      />

      <CampaignButtons
        submitLabel={t('next')}
        saveDraftLabel={t('save')}
        onSubmit={() => handleSubmitAll('next')}
        onSaveDraft={() => handleSubmitAll('save')}
      />
    </div>
  )
}
