import { textWrapper } from '@/utils/graph-text-wrapper'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Scale,
  CoreScaleOptions,
  Tick,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

export type ChartData = {
  label: string
  data: string
}
type Props = {
  data: ChartData[]
}

export default function BarChartHorizontal({ data }: Props) {
  // console.log({ data })
  ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip)
  const limit = 18
  const dataWithModifiedLabels = textWrapper(data, limit)

  const optionsBarChart = {
    indexAxis: 'y' as const,
    maintainAspectRatio: false,
    barThickness: 20,
    plugins: {
      legend: {
        display: false,
        labels: {
          font: {
            size: 12,
          },
        },
      },
      title: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        display: false,
        grid: {
          display: false,
        },
        min: 0, // Set minimum value for x-axis
        max: 1, // Force maximum to ensure consistent scale
      },
      y: {
        border: { display: false },
        grid: {
          display: false,
        },
        // Make sure all labels are displayed
        ticks: {
          callback: function (
            this: Scale<CoreScaleOptions>,
            _tickValue: number | string,
            index: number,
            _ticks: Tick[],
          ) {
            // index is the position in the array, which matches our data array
            const label = dataWithModifiedLabels[index]?.label
            return label ? (Array.isArray(label) ? label.join(' ') : label) : ''
          },
        },
      },
    },
  }

  const incentiveBgColors = [
    'rgba(208, 232, 255, 0.4)',
    'rgba(0, 111, 253, 0.4)',
    'rgba(24, 18, 255, 0.4)',
    'rgba(9, 17, 133, 0.4)',
    'rgba(208, 232, 255, 0.4)',
    'rgba(0, 111, 253, 0.4)',
  ]

  const incentiveBorderColors = [
    'rgba(208, 232, 255, 1)',
    'rgba(0, 111, 253, 1)',
    'rgba(24, 18, 255, 1)',
    'rgba(9, 17, 133, 1)',
    'rgba(208, 232, 255, 1)',
    'rgba(0, 111, 253, 1)',
  ]

  // Flatten the labels from arrays to strings for proper display
  const flattenedLabels = dataWithModifiedLabels.map((d) =>
    Array.isArray(d.label) ? d.label.join(' ') : d.label,
  )

  const dataCampaignOverview = {
    // Use the flattened labels instead of array labels
    labels: flattenedLabels,
    datasets: [
      {
        label: '',
        data: dataWithModifiedLabels.map((d) => parseFloat(d.data) || 0),
        backgroundColor: incentiveBgColors,
        borderColor: incentiveBorderColors,
        borderWidth: 1,
        borderRadius: 0,
        borderSkipped: false,
      },
    ],
  }
  return <Bar options={optionsBarChart} data={dataCampaignOverview} />
}
