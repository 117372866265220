import * as React from 'react'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import { DayPicker, useDayPicker, useNavigation } from 'react-day-picker'

import { cn } from '@/lib/utils'
import { buttonVariants } from '@/components/ui/button'
import { Combobox } from '@/components/ui/combobox'
import {
  format,
  getMonth,
  monthsInYear,
  setMonth,
  setYear,
  startOfDay,
} from 'date-fns'
import { type DayContentProps } from 'react-day-picker'
import { LatestPurchaseDateRange } from '../../../../server/src/graphql'
import { useTranslation } from 'react-i18next'
import enUS from 'date-fns/locale/en-US'
import nl from 'date-fns/locale/nl'

export type CalendarProps = React.ComponentProps<typeof DayPicker>

type PickerDropdownProps = {
  value: number
  setValue: (value: number) => void
  className?: string
}
type ExtendedCalendarProps = CalendarProps & {
  latestPurchaseDateRange?: LatestPurchaseDateRange
}

export function CustomDayContent(
  props: DayContentProps,
  latestPurchaseDateRange?: LatestPurchaseDateRange,
) {
  return (
    <span
      className={cn(
        latestPurchaseDateRange &&
          props.date >=
            startOfDay(
              new Date(
                latestPurchaseDateRange.startDate.toString().substr(0, 10),
              ),
            ) &&
          props.date <=
            startOfDay(
              new Date(
                latestPurchaseDateRange.endDate.toString().substr(0, 10),
              ),
            )
          ? 'flex bg-secondaryShades w-9 h-9 rounded-lg items-center justify-center'
          : undefined,
      )}
    >
      {props.date.getDate()}
    </span>
  )
}
const MonthDropdown = ({ value, ...props }: PickerDropdownProps) => {
  const { i18n } = useTranslation()
  const dateLocale = i18n.language === 'nl' ? nl : enUS

  const options = Array.from({ length: monthsInYear }, (_, i) => {
    const month = setMonth(new Date(), i)
    return {
      value: i.toString(),
      label: format(month, 'MMMM', { locale: dateLocale }),
    }
  })

  return (
    <Combobox
      type="single"
      {...props}
      value={value.toString()}
      setValue={(v) => props.setValue(Number(v))}
      options={options}
    />
  )
}

const YearDropdown = ({ value, ...props }: PickerDropdownProps) => {
  const picker = useDayPicker()
  const fromYear = picker.fromYear ?? 1900
  const toYear = picker.toYear ?? new Date().getFullYear()

  const options = Array.from({ length: toYear - fromYear + 1 }, (_, i) => {
    const year = (toYear - i).toString()
    return {
      value: year,
      label: year,
    }
  })

  return (
    <Combobox
      type="single"
      {...props}
      value={value.toString()}
      setValue={(v) => props.setValue(Number(v))}
      options={options}
    />
  )
}

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  latestPurchaseDateRange,
  ...props
}: ExtendedCalendarProps) {
  const { t, i18n } = useTranslation()

  // Import the appropriate locale based on the current language
  const dateLocale = React.useMemo(() => {
    return i18n.language === 'nl' ? nl : enUS
  }, [i18n.language])

  return (
    <>
      <DayPicker
        defaultMonth={new Date()}
        weekStartsOn={1}
        showOutsideDays={showOutsideDays}
        className={cn('p-3', className)}
        locale={dateLocale}
        formatters={{
          formatMonthCaption: (date) =>
            format(date, 'MMMM', { locale: dateLocale }),
          formatWeekdayName: (date) =>
            format(date, 'EEEEEE', { locale: dateLocale }),
        }}
        classNames={{
          months:
            'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
          month: 'space-y-4',
          caption:
            'flex justify-between pt-1 relative items-center gap-2 px-2 [&_div[aria-hidden=true]]:hidden',
          caption_label: 'text-sm font-medium',
          caption_dropdowns: 'flex justify-center gap-2',
          nav: 'space-x-1 flex items-center',
          nav_button: cn(
            buttonVariants({ variant: 'outline' }),
            'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100',
          ),
          vhidden: 'sr-only',
          nav_button_previous: 'left-1',
          nav_button_next: 'right-1',
          table: 'w-full border-collapse space-y-1',
          head_row: 'flex',
          head_cell: 'text-black-2 rounded-md w-9 font-normal text-[0.8rem]',
          row: 'flex w-full mt-2',
          cell: 'h-9 w-9 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected])]:bg-white first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
          day: cn(
            buttonVariants({ variant: 'ghost' }),
            'h-9 w-9 p-0 font-normal aria-selected:opacity-100 shadow-none',
          ),
          day_range_end: 'bg-primary',
          day_range_start: 'bg-primary',
          day_selected: 'bg-primary text-white focus:text-white',
          day_today: 'bg-ui1 text-black-1 focus:bg-ui1 focus:text-black-1',
          day_outside:
            'day-outside text-black-2 opacity-50 aria-selected:bg-ui1/50 aria-selected:text-black-2 aria-selected:opacity-30',
          day_disabled: 'text-black-2 opacity-50',
          day_range_middle: 'aria-selected:bg-ui1 aria-selected:text-black-1',
          day_hidden: 'invisible',
          ...classNames,
        }}
        components={{
          IconLeft: ({ ...props }) => (
            <ChevronLeft className="h-4 w-4" {...props} />
          ),
          IconRight: ({ ...props }) => (
            <ChevronRight className="h-4 w-4" {...props} />
          ),
          DayContent: ({ ...props }) =>
            CustomDayContent(props, latestPurchaseDateRange),
          Dropdown: ({ ...props }) => {
            const navigation = useNavigation()
            const month = getMonth(navigation.currentMonth)
            const year = navigation.currentMonth.getFullYear()
            const type = props.name === 'years' ? 'years' : 'months'

            const onMonthChange = (value: number) => {
              const newDate = setMonth(navigation.currentMonth, value)
              navigation.goToMonth(newDate)
            }

            return type === 'months' ? (
              <MonthDropdown
                value={month}
                setValue={onMonthChange}
                className="w-32"
              />
            ) : (
              <YearDropdown
                value={year}
                setValue={(value) =>
                  navigation.goToMonth(setYear(navigation.currentMonth, value))
                }
                className="w-20"
              />
            )
          },
        }}
        {...props}
      />
      {latestPurchaseDateRange && (
        <div className="flex gap-x-4 ml-4 items-center my-4 ">
          <div className="h-9 w-9 bg-secondaryShades rounded-lg flex items-center justify-center">
            {t('day')}
          </div>
          <span> {t('previous_campaign')}</span>
        </div>
      )}
    </>
  )
}
Calendar.displayName = 'Calendar'

export { Calendar }
