import { z } from 'zod'

export enum ECFlowScreensType {
  HOME = 'home',
  INCENTIVE = 'incentive',
  QUESTIONNAIRE = 'questionnaire',
  INVITE = 'invite',
  HAPPINESS_CHECK = 'happiness-check',
  SUCCESS = 'success',
  EXPIRED = 'expired',
}

export enum TypeOfUser {
  END_USER = 'end-user',
  REFERRAL_USER = 'referral-user',
}

export const emailSchema = z.object({
  header: z.string().min(1, 'required'),
  text: z.string().min(1, 'required'),
})

const translationSchema = z.object({
  header: z.string().optional(),
  text: z.string().optional(),
  termsAndConditions: z.string().optional(),
  termsAndConditionsEndUser: z.string().optional(),
  termsAndConditionsReferral: z.string().optional(),
  termsAndConditionsSummary: z.string().optional(),
})

const dynamicKeySchema = z.object({
  //TODO change nl to dynamic key based on all supported languages
  nl: translationSchema,
})

export const ecFlowTextsSchema = z
  .object(
    Object.values(ECFlowScreensType).reduce((obj, key) => {
      return {
        ...obj,
        [key]: dynamicKeySchema.optional(),
      }
    }, {}),
  )
  .optional()

export enum EmailNotificationTrigger {
  SUCCESS = 'successfullySelectedIncentive',
  NO_ACTION = 'noActionTaken',
  INCOMPLETE = 'incompleteOnlineProcess',
  HAPPINESS = 'happinessCheck',
}

export const emailNotificationSchema = z
  .object({
    trigger: z.nativeEnum(EmailNotificationTrigger),
    header: z.string().min(1, 'Required'),
    text: z.string().min(1, 'Required'),
    imageUrl: z.string().optional(),
    date: z.string().optional(),
    happinessRating: z.array(z.string()).optional(),
  })
  .superRefine((data, ctx) => {
    switch (data.trigger) {
      case EmailNotificationTrigger.NO_ACTION:
        if (!data.date) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Required',
            path: ['date'],
          })
        }
        break
      case EmailNotificationTrigger.HAPPINESS:
        if (!data.happinessRating || data.happinessRating.length === 0) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Required',
            path: ['happinessRating'],
          })
        }
        break
    }
  })

const reminderBaseSchema = z.object({
  header: z.string().min(1, 'Required'),
  text: z.string().min(1, 'Required'),
  imageUrl: z.string().optional(),
})

// Specific schemas for each reminder type
const noActionTakenSchema = reminderBaseSchema.extend({
  date: z.string().min(1, 'Required'),
})

const happinessCheckSchema = reminderBaseSchema.extend({
  happinessRating: z.array(z.string()).min(1, 'Required'),
})

export const CreateECFlowTextsSchema = z.object({
  id: z.string().optional(),
  ecFlowTexts: z
    .object({
      [TypeOfUser.END_USER]: ecFlowTextsSchema,
      [TypeOfUser.REFERRAL_USER]: ecFlowTextsSchema,
      email: z.object({
        nl: emailSchema.required(),
      }),
    })
    .optional(),
  reminders: z
    .object({
      enabled: z.boolean().optional(),
      noActionTaken: z.union([z.undefined(), noActionTakenSchema]),
      incompleteOnlineProcess: z.union([z.undefined(), reminderBaseSchema]),
      happinessCheck: z.union([z.undefined(), happinessCheckSchema]),
    })
    .optional(),
})
export type ECFlowTextsFormType = z.infer<typeof CreateECFlowTextsSchema>
export type ECFlowTextsType = z.infer<typeof ecFlowTextsSchema>
export type EcFlowTexts = Pick<ECFlowTextsFormType, 'ecFlowTexts'>
export type EmailNotificationFormData = z.infer<typeof emailNotificationSchema>
export type CampaignReminders = NonNullable<ECFlowTextsFormType['reminders']>
