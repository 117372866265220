import { CalendarIcon } from '@radix-ui/react-icons'
import { format } from 'date-fns'
import enUS from 'date-fns/locale/en-US'
import nl from 'date-fns/locale/nl'

import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/calendar'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import React from 'react'
import { DateRange } from 'react-day-picker'
import { LatestPurchaseDateRange } from '@/gql'
import { useTranslation } from 'react-i18next'

export function DateRangePicker({
  value,
  setValue,
  latestPurchaseDateRange,
}: {
  setValue: (value: DateRange | undefined) => void
  value?: DateRange
  latestPurchaseDateRange?: LatestPurchaseDateRange
}) {
  const { t, i18n } = useTranslation()
  const [date, setDate] = React.useState<DateRange | undefined>(value)

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={'outline'}
          className={cn(
            'datePickerClient bg-white',
            'w-full pl-3 text-left font-normal',
            !value && 'text-muted-foreground',
          )}
        >
          {date?.from ? (
            date.to ? (
              <>
                {format(date.from, 'LLL dd, y', {
                  locale: i18n.language === 'nl' ? nl : enUS,
                })}{' '}
                -{' '}
                {format(date.to, 'LLL dd, y', {
                  locale: i18n.language === 'nl' ? nl : enUS,
                })}
              </>
            ) : (
              format(date.from, 'LLL dd, y', {
                locale: i18n.language === 'nl' ? nl : enUS,
              })
            )
          ) : (
            <span>{t('pick_a_date')}</span>
          )}
          <CalendarIcon className="ml-2 h-4 w-4 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <Calendar
          initialFocus
          mode="range"
          defaultMonth={date?.from}
          selected={date}
          onSelect={(v) => {
            setValue({ from: v?.from, to: v?.to })
            setDate(v)
          }}
          numberOfMonths={2}
          latestPurchaseDateRange={latestPurchaseDateRange}
          locale={i18n.language === 'nl' ? nl : enUS}
        />
      </PopoverContent>
    </Popover>
  )
}
