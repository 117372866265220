import { useState } from 'react'
import { Controller } from 'react-hook-form'
import { Check, ChevronsUpDown } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { Command, CommandGroup, CommandItem } from '@/components/ui/command'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { FormProps } from '@sourcelabbg/form/lib'
import { cn } from '@/lib/utils'
import { CustomMultiSelect as CustomMultiSelectType } from './custom-types'

export type CustomMultiSelectProps = {
  field: CustomMultiSelectType
  formProps: FormProps
}

export function CustomMultiSelect({
  field,
  formProps,
}: CustomMultiSelectProps) {
  const [open, setOpen] = useState(false)

  return (
    <Controller
      control={formProps.control}
      name={field.name}
      render={({ field: { onChange, value = [] } }) => (
        <Popover open={open} onOpenChange={setOpen}>
          <PopoverTrigger asChild>
            <Button
              variant="outline"
              className={cn(
                'w-full justify-between',
                field.uiOptions?.className,
              )}
              disabled={field.uiOptions?.disabled}
            >
              {value.length > 0
                ? value
                    .map(
                      (v: string) =>
                        field.options.find((o) => o.value === v)?.label,
                    )
                    .join(', ')
                : field.uiOptions?.placeholder || 'Select options'}
              <ChevronsUpDown className="ml-2 h-4 w-4 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-[--radix-popover-trigger-width] p-0">
            <Command className="pointer-events-auto w-full">
              <CommandGroup>
                {field.options.map((option) => (
                  <CommandItem
                    key={option.value}
                    onSelect={() => {
                      const newValue = Array.isArray(value) ? value : []
                      onChange(
                        newValue.includes(option.value)
                          ? newValue.filter((v) => v !== option.value)
                          : [...newValue, option.value],
                      )
                    }}
                    className="flex items-center justify-between w-full"
                    disabled={option.disabled}
                  >
                    {option.label}
                    {value?.includes(option.value) && (
                      <Check className="h-4 w-4 text-primary" />
                    )}
                  </CommandItem>
                ))}
              </CommandGroup>
            </Command>
          </PopoverContent>
        </Popover>
      )}
    />
  )
}
