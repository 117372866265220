import { Trash } from '@phosphor-icons/react'
import AlphaForm from '../form'
import { AlphaTypes } from '../form/custom-types'
import { Button } from '../ui/button'
import { zodResolver } from '@hookform/resolvers/zod'
import { emailNotificationSchema } from '@/schema/ec-flow-texts.schema'
import { useToast } from '@/hooks/use-toast'
import { LayoutProps } from '@sourcelabbg/form/lib'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EmailFormData } from '@/pages/campaign/emails'

interface EmailFormProps {
  onDelete?: () => void
  onClose?: () => void
  onSubmit: (data: EmailFormData) => void
  header: string
  triggerType: string
  values?: EmailFormData
  existingTriggerTypes: string[]
  currentIndex: number
  campaignId: string
  campaignStartDate: string
  campaignEndDate: string
}

export const EmailForm = ({
  onDelete,
  onClose,
  onSubmit,
  triggerType,
  values,
  existingTriggerTypes,
  currentIndex,
  campaignId,
  campaignStartDate,
  campaignEndDate,
}: EmailFormProps) => {
  const { t } = useTranslation()
  const { toast } = useToast()
  const [newValues, setNewValues] = useState<EmailFormData>(values ?? {})

  // Do not translate because it is used in the email template, if we save the text in EN will be send in the email in EN
  const emailTemplates = {
    noActionTaken: {
      header: 'Maak uw cadeaukeuze af',
      text: 'We zien dat u uw cadeau nog niet heeft geselecteerd. Mis deze kans niet!',
      imageUrl: '',
    },
    incompleteOnlineProcess: {
      header: 'Uw cadeaukeuze is niet afgerond',
      text: 'U bent er bijna! Rond uw cadeauselectie af om uw beloning te ontvangen.',
      imageUrl: '',
    },
    happinessCheck: {
      header: 'Deel uw positieve ervaring',
      text: 'Bedankt voor uw positieve feedback! We zouden het geweldig vinden als u uw ervaring wilt delen.',
      imageUrl: '',
      happinessRating: [],
    },
  }

  type EmailTriggerType = keyof typeof emailTemplates

  const onChange = (field: string, change: { old: string; new: string }) => {
    if (field === 'trigger') {
      const triggerType = change.new as EmailTriggerType
      if (triggerType in emailTemplates) {
        const template = emailTemplates[triggerType]
        setNewValues((prev) => ({
          ...prev,
          header: template.header,
          text: template.text,
          trigger: change.new,
          imageUrl: prev.imageUrl || template.imageUrl,
        }))
      } else {
        // For triggers without templates, just update the trigger field
        setNewValues((prev) => ({
          ...prev,
          trigger: change.new,
        }))
      }
    } else if (field === 'imageUrl') {
      setNewValues((prev) => ({
        ...prev,
        imageUrl: change.new,
      }))
    }
  }

  const handleSubmit = (data: EmailFormData) => {
    const triggerExists = existingTriggerTypes.some(
      (type, index) => type === data.trigger && index !== currentIndex,
    )

    if (triggerExists) {
      toast({
        title: t('emails.toast'),
        description: t('emails.toast_description'),
        duration: 3000,
      })
      return
    }

    onSubmit(data)
    if (onClose) {
      onClose()
    }
  }

  const createEmailFields: AlphaTypes[] = [
    {
      name: 'imageUrl',
      type: 'custom',
      customControlType: 'fileUpload',
      uploadUrl: '/upload/email-notification',
      uploadBody: {
        campaignId,
        triggerType,
      },
      allowedFileTypes: ['jpg', 'png'],
      uiOptions: {
        label: t('upload_image'),
        visible: (formValues) =>
          formValues?.trigger !== 'successfullySelectedIncentive',
      },
    },
    {
      name: 'trigger',
      type: 'custom',
      customControlType: 'shadcn-select',
      options: [
        {
          value: 'successfullySelectedIncentive',
          label: t('Successfully Selecting an Incentive'),
          disabled:
            existingTriggerTypes.includes('successfullySelectedIncentive') &&
            triggerType !== 'successfullySelectedIncentive',
        },
        {
          value: 'noActionTaken',
          label: t('emails.no_action_taken'),
          disabled:
            existingTriggerTypes.includes('noActionTaken') &&
            triggerType !== 'noActionTaken',
        },
        {
          value: 'incompleteOnlineProcess',
          label: t('emails.incomplete_online_process'),
          disabled:
            existingTriggerTypes.includes('incompleteOnlineProcess') &&
            triggerType !== 'incompleteOnlineProcess',
        },
        {
          value: 'happinessCheck',
          label: t('emails.happiness_check'),
          disabled:
            existingTriggerTypes.includes('happinessCheck') &&
            triggerType !== 'happinessCheck',
        },
      ],
      uiOptions: {
        label: t('emails.trigger'),
        placeholder: t('emails.trigger_description'),
        disabled: triggerType === 'successfullySelectedIncentive',
      },
      value: triggerType,
    },
    {
      render: (layoutProps: LayoutProps) => {
        return (
          (layoutProps.formValues?.trigger === 'incompleteOnlineProcess' ||
            layoutProps.formValues?.trigger === 'happinessCheck') && (
            <p className=" text-blue-700 text-sm mb-2">
              {t('emails.email_sent_24h_after')}
            </p>
          )
        )
      },
    },
    {
      name: 'date',
      type: 'custom',
      customControlType: 'dateInput',
      uiOptions: {
        label: t('emails.send_out'),
        visible: (formValues) => formValues?.trigger === 'noActionTaken',
      },
      minDate: new Date(campaignStartDate),
      maxDate: new Date(campaignEndDate),
    },

    {
      name: 'happinessRating',
      type: 'custom',
      customControlType: 'multiSelect',
      options: [
        { value: '4', label: t('emails.rating_4') },
        { value: '5', label: t('emails.rating_5') },
      ],
      uiOptions: {
        label: t('emails.rating'),
        placeholder: t('emails.select_rating'),
        visible: (formValues) => formValues?.trigger === 'happinessCheck',
      },
    },
    {
      name: 'header',
      type: 'input',
      uiOptions: {
        label: t('emails.email_heading'),
        inputType: 'text',
      },
    },
    {
      name: 'text',
      type: 'custom',
      customControlType: 'quillEditor',
      uiOptions: {
        label: t('emails.email_text'),
        className: 'h-40',
      },
      maxChars: 500,
    },
    {
      render: () => (
        <div className="flex justify-between pt-8">
          <Button
            type="button"
            variant="outline"
            onClick={onDelete}
            disabled={
              triggerType === 'successfullySelectedIncentive' ||
              !values?.trigger
            }
          >
            <Trash className="mr-2 h-4 w-4 text-primary" />
            {t('emails.delete_email')}
          </Button>
          <div className="grid grid-cols-2 gap-4 justify-end items-end">
            <Button
              type="button"
              variant="outline"
              onClick={onClose}
              className="border border-primary text-primary hover:border-primary/80 hover:text-primary/80"
            >
              {t('cancel')}
            </Button>
            <Button className="text-white" type="submit" variant="default">
              {t('save')}
            </Button>
          </div>
        </div>
      ),
    },
  ]

  return (
    <AlphaForm
      fields={createEmailFields}
      onSubmit={handleSubmit}
      values={newValues}
      onFieldChange={onChange}
      resolver={zodResolver(emailNotificationSchema)}
    />
  )
}
